<template>
  <div>
    <v-text-field
      dense
      outlined
      counter
      @keypress.enter="focus('.code input')"
      :label="$t('data.name')"
      :error="!!apiError.name"
      :error-messages="apiError.name"
      autofocus
      maxlength="100"
      v-model="formData.name"
    ></v-text-field>

    <v-text-field
      dense
      outlined
      counter
      class="code"
      :label="$t('data.code')"
      :error="!!apiError.code"
      :error-messages="apiError.code"
      maxlength="100"
      v-model="formData.code"
    ></v-text-field>

    <!-- 未啟用規格時 -->
    <v-btn
      color="primary"
      outlined
      class="mr-4"
      @click="setupInstanceConfig"
    >
      <span>{{'product.action.setup_instance_config'| t}}</span>
    </v-btn>

    <v-btn
      v-if="hasSetupInstanceConfig"
      color="primary"
      outlined
      @click="disabledInstanceConfig"
    >
      <span>{{'product.action.disabled_instance_config'| t}}</span>
    </v-btn>

    <div v-if="hasSetupInstanceConfig" class="my-2">
      <div class="subtitle-1 font-weight-bold mb-2">{{'product.data.spec'| t}}</div>

      <div
        v-for="(config, configIndex) in formData.instance_config"
        :key="configIndex"
      >
        <div>
          <span class="body-2 font-weight-bold">
            {{config.name}}
          </span>
          : {{ config.attributes.join('/') }}
        </div>
        <br>
      </div>
    </div>
  </div>
</template>

<script lang="babel" type="text/babel">
import listCreatePopupMixin from '@/components/list/listCreateButton/listCreatePopupMixin'
import uuidV4 from 'uuid/v4'
export default {
  mixins: [listCreatePopupMixin],
  data: () => ({
    formData: {
      name: null,
      code: uuidV4(),
      instance_config: null,
      instances: [],
    },
  }),
  mounted() {
    this.init()
  },
  methods: {
    setupInstanceConfig() {
      this.$apopup.base({
        title: this.$t('product.action.setup_instance_config'),
        width: '550px',
        bodySlot: () => import('modules/product/components/productInstanceConfigPopup.vue'),
        applyOnEnter: false,
        defaultValue: window.eagleLodash.cloneDeep(this.formData.instance_config),
        disabledApply: data => {
          if(!data) return true
          if(!Array.isArray(data)) return true
          if(data.length == 0) return true
          return false
        },
        applyCallback: instanceConfig => {
          this.setInstanceConfig(instanceConfig)
          this.setInstances(instanceConfig)
        },
      })
    },
    setInstanceConfig(instanceConfig) {
      if(!Array.isArray(instanceConfig)) {
        this.$set(this.formData, 'instance_config', null)
        return
      }
      if(instanceConfig.length == 0) {
        this.$set(this.formData, 'instance_config', null)
        return
      }
      this.$set(this.formData, 'instance_config', instanceConfig)
    },
    setInstances(instanceConfig) {
      this.formData.instances = this.$helper.createProductInstanceSets(instanceConfig)
    },
    disabledInstanceConfig() {
      this.$set(this.formData, 'instance_config', null)
      this.$set(this.formData, 'instances', this.getDefaultProductInstanceMode())
    },
    getDefaultProductInstanceMode() {
      return {
        name: null,
      }
    },
    init() {
      this.formData.instances = [
        this.getDefaultProductInstanceMode()
      ]
    },
  },
  computed: {
    hasSetupInstanceConfig() {
      if(!this.formData) return false
      if(!Array.isArray(this.formData.instance_config)) return false
      if(this.formData.instance_config.length == 0) return false
      return this.formData.instance_config.length > 0
    },
  },
  watch: {
    formData: {
      immediate: true,
      deep: true,
      handler() {
        this.$emit('updateData', window.eagleLodash.cloneDeep(this.formData))
      },
    }
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>